import { Injectable } from '@angular/core';
import { Inject } from '@angular/core';

import { downgradeInjectable } from '@angular/upgrade/static';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as Flow from '@flowjs/flow.js';
import { SpotResource } from './resources/spot-resource';
import { EndPointService } from './endpoint-service';
import { WINDOW } from '../communication/window-provider';
import { AuthConstant } from '../../../constants/auth.constant';
import { CtsBulkUploadDialog } from './cts-bulk-upload-dialog/cts-bulk-upload-dialog.component';
import { CtsBulkUploadProgressDialog, DialogData } from './cts-bulk-upload-progress-dialog/cts-bulk-upload-progress-dialog.component';
import { CtsBulkUploadInstructionsDialog } from './cts-bulk-upload-instructions-dialog/cts-bulk-upload-instructions-dialog.component';
import { CtsBulkUploadOptionsDialog } from './cts-bulk-upload-options-dialog/cts-bulk-upload-options-dialog.component';
import { Subject } from 'rxjs';
import { GoogleAnalyticsServiceService } from './google-analytics-service.service';

declare var angular: angular.IAngularStatic;

@Injectable({
    providedIn: 'root',
})
export class BulkUploadService {

    public files: DialogData = {};
    private showUploadingMessage = new Subject<boolean>(); // Source
    private sendSpotDetails = new Subject<any>();
    showUploadingMessage$ = this.showUploadingMessage.asObservable(); // Stream
    sendSpotDetails$ = this.sendSpotDetails.asObservable();
    isBackgroundProcess : boolean = false;
    constructor(
        public dialog: MatDialog,
        private spotResource: SpotResource,
        private endPointService: EndPointService,
        @Inject(WINDOW) private window: Window,
        private _authConstants: AuthConstant,
        private _snackBar: MatSnackBar,
        private uploadDialogRef: MatDialogRef<CtsBulkUploadDialog>,
        private googleAnalytics: GoogleAnalyticsServiceService
    ) {
    }

    // Method to show bulk upload dialog component
    // -- This component should handle spot file selection, isci-validation via Node, and metadata entry
    openUploadDialog(): void {
        this.uploadDialogRef = this.dialog.open(CtsBulkUploadDialog, {
            disableClose: true,
            panelClass: 'cts-bulk-upload-dialog',
            data: {
                spots: [
                    { isci: 'test' }
                ]
            }
        });
    }
    //dialog when user click start upload button on bulk upload dialog
    openOptionsDialog(spots:any): void {
        this.isBackgroundProcess = false;
        if (spots) {
            this._createSpots(spots);
        }
        const dialogRef  = this.dialog.open(CtsBulkUploadOptionsDialog, {
            width: '450px',
            disableClose: true,
            panelClass: 'cts-bulk-upload-options-dialog'
        });
        dialogRef.afterClosed().subscribe(result => {
            // Add code here to track usage of bulk upload *******
            let items2 = Object.keys(spots).length;
            this.googleAnalytics.logCustomEvent("Bulk Upload","Upload","Number of items uploaded :"+items2);
            if(result === 'background'){
                this.isBackgroundProcess = true;
                this.uploadDialogRef.close();
                this.openInstructionDialog();
            } else if(result === 'viewStatus') {
                this.showUploadingMessage.next(true);
                dialogRef.close();
            }
        })
    }
    //dialog when user choose run the upload in background
    openInstructionDialog(): void {
        this.dialog.open(CtsBulkUploadInstructionsDialog, {
            width: '400px',
            disableClose: true,
            panelClass: 'cts-bulk-upload-instructions-dialog',
        });
    }

    // This is  the dialog that would let a user see how far their upload has come
    openProgressDialog(): void {
        this.dialog.open(CtsBulkUploadProgressDialog, {
            // width: '250px',
            disableClose: true,
            panelClass: 'cts-progress-panel',
            data: this.files,
        });
    }

    /* Private Methods */
    private async _createSpots(spotHash: {[index: string]: any}) {
        let spotList:any = [];
        let creationPromises = [];

        for (const [key, value] of Object.entries(spotHash)) {
            if(value.format === 'HD') {
                spotHash[key].hdFlag = true;
            }
            spotHash[key].mediaType = value.format === 'RADIO' ? 'AUDIO' : 'VIDEO';
            spotHash[key].downConvertPreference = 'CENTERCUT';

            spotHash[key].id = (spotHash[key].id)? spotHash[key].id : -1;

            // Setup the stuff for A/A/B
            spotHash[key].agencyId = (spotHash[key].agency)? spotHash[key].agency.id: null;
            spotHash[key].advertiserId = (spotHash[key].advertiser)? spotHash[key].advertiser.id: null;
            spotHash[key].brandId = (spotHash[key].brand)? spotHash[key].brand.id: null;

            spotList.push(spotHash[key]);

            creationPromises.push(
                this.spotResource.create({}, spotHash[key])
            );
        }

        // Wait for all of the spots to be created or fail to create
        let spotIds:any = await Promise.allSettled(creationPromises);

        // Multi-file Upload Code ====================================================
        let uploader = new Flow({
            target: this.endPointService.tapUploadEndPoint,
            headers: () => {
                let accountHash = JSON.parse(
                    this.window.localStorage[this._authConstants.session.ACCOUNT_HASH]
                );
                let sessionData = JSON.parse(
                    this.window.sessionStorage[this._authConstants.session.SESSION_DATA]
                );

                return {
                    Authorization: 'Bearer ' + accountHash[sessionData.accountId],
                };
            },
            testMethod: false, // Don't check to see if the chunks/files exist first
            testChunks: false, // used in conjunction with above testMethod
            fileParameterName: 'file',
            uploadMethod: 'POST',
            withCredentials: true,
            allowDuplicateUploads: true,
            // 5Gb chunks, should force everything to go at once, since it's limited to that max size
            chunkSize: 5 * 1024 * 1024 * 1024,
            simultaneousUploads: 1,
            query: {
                ignoreLoadingBar: true,
                waitForAutoConversions: false,
            },
        });

        // Get rid of any pre-existing event handlers
        uploader.off();

        uploader.on('fileAdded', (file:any) => {
            file.spotId = file.file.spotId;
            this.files[file.file.spotId] = {
                progress: 0,
                filename: file.file.name,
            };
        });

        for(let x = 0; x < spotIds.length; x++) {
            if(spotIds[x].status === 'fulfilled') {
                // Give the spot its generated ID as well
                spotList[x].id = spotIds[x].value;
                this.sendSpotDetails.next(spotList[x]);
                if (spotList[x].file) {
                    // Append some tracking info to the file before it's added to the upload queue
                    spotList[x].file.spotId = spotIds[x].value;

                    // Add the spot file to the uploader
                    uploader.addFile(spotList[x].file);
                } else {
                    spotList[x].uploading = false;
                    spotList[x].progress = 100;
                }
            }
        }

        uploader.on('fileProgress', (file:any) => {
            this.files[file.spotId].progress = (file.progress() * 100);

            // Find and update the spot with its upload progress
            for(let i = 0; i < spotList.length; i++) {
                if(spotList[i].id === file.spotId) {
                    if (file.progress() === 1) {
                        spotList[i].uploading = false;
                        spotList[i].progress = 100;
                    } else {
                        spotList[i].progress = Math.floor(file.progress() * 100);
                    }
                }
            }
        });

        // When a file finishes uploading, grab the assetId and assign it to the spot object
        uploader.on('fileSuccess', (file:any, message:any) => {
            // Remove this file from the progress list because it is done
            delete this.files[file.spotId];

            // Find and update the right spot
            for(let i = 0; i < spotList.length; i++) {
                if(spotList[i].id === file.spotId) {
                    this.spotResource.ingestUploadedMediaWithTap({ id: spotList[i].id, assetContentId: message }, {}).subscribe(
                        () => {
                            if(this.isBackgroundProcess){
                                setTimeout(() => {
                                    this._snackBar.open('Successfully created spot \'' + spotList[i].isci + '\'', 'Okay', {
                                        duration: 2000,
                                    });
                                }, 0);
                            }

                        },
                        (err:any) => {
                            setTimeout(() => {
                                this._snackBar.open('Spot ' + spotList[i].isci + ' failed to begin ingest in MIE', 'Darn', {
                                    duration: 2000,
                                });
                            }, 0);
                        }
                    );
                }
            }
        });

        // Start the uploads
        uploader.upload();
    }
}

angular.module('comcast.common.communication').factory('BulkUploadService', downgradeInjectable(BulkUploadService));