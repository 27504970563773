import { Injectable } from '@angular/core';

import { downgradeInjectable } from '@angular/upgrade/static';

declare var angular: angular.IAngularStatic;

@Injectable()
export class EndPointService {
    // Where does the API Server live?
    apiServerUrl: string;

    socketEndpoint: string;

    loginEndpoint: string;
    logoutEndpoint: string;
    registerLiteEndpoint: string;
    switchAccountEndPoint: string;
    sessionKeyEndPoint: string;
    passwordResetEndPoint: string;
    passwordChangeEndPoint: string;
    viewEulaEndPoint: string;
    acceptEulaEndPoint: string;
    agenciesEndPoint: string;
    advertisersEndPoint: string;
    brandsEndPoint: string;
    receiverBrandsEndPoint: string;
    campaignsEndPoint: string;
    receiverCampaignsEndPoint: string;
    batonTestPlanEndPoint: string;
    destinationEndPoint: string;
    grantedDestinationsEndPoint: string;
    orderGroupDestinationsEndPoint: string;
    destinationTypeEndPoint: string;
    destinationListEndPoint: string;
    destinationListImportEndPoint: string;
    destinationListWithSystemEndPoint: string;
    durationListEndPoint: string;
    welcomeMessageEndPoint: string;
    factoryEndPoint: string;
    vantageFactoryEndPoint: string;
    marketEndPoint: string;
    mediaFormatEndPoint: string;
    securityRoleEndPoint: string;
    securityRolePermContextEndPoint: string;
    jobsEndPoint: string;
    clusterNodeEndPoint: string;
    audioChannelEndPoint: string;
    acceptTemplateEndPoint: string;
    spotMetadataTransFormEndPoint: string;
    videoFormatProfileEndPoint: string;
    userEndPoint: string;
    jobClassificationEndPoint: string;
    accountEndPoint: string;
    accountFriendOfEndPoint: string;
    accountFriendsEndPoint: string;
    providerEndPoint: string;
    providerListEndPoint: string;
    receiverEndPoint: string;
    receiverListEndPoint: string;
    tapeVendorEndPoint: string;
    qcVendorEndPoint: string;
    prodSvcsVendorEndPoint: string;

    // Deliveries
    deliveryEndPoint: string;
    deliveryProviderEndPoint: string;
    deliveryAssetMapEndPoint: string;
    deliveryTrafficAssetEndPoint: string;
    deliveryCorollaryInfoEndPoint: string;
    liteDeliveryEndPoint: string;
    deliveryOriginalEndPoint: string;
    deliveryProxyEndPoint: string;
    referenceLinkEndPoint: string;
    metadataOverrideEndPoint: string;
    customizationEndPoint: string;

    // Spots
    spotEndPoint: string;
    spotStatusEndPoint: string;
    checkIsciEndPoint: string;
    tapIngestEndPoint: string;
    tapUploadEndPoint: string;
    providerSpotEndPoint: string;
    spotSubmitEndPoint: string;
    spotUploadEndPoint: string;
    uploadCCAssetEndpoint: string;
    uploadCCSubmitEndpoint: string;
    spotImportEndPoint: string;
    spotAssetMapEndPoint: string;
    spotAssetStatsEndPoint: string;
    spotMetadataEndPoint: string;
    spotRecallEndPoint: string;
    spotVerifyEndPoint: string;
    spotAssignEndPoint: string;
    spotSetFriendsEndPoint: string;
    spotGetAdIDEndPoint: string;
    spotSaveAdIDEndPoint: string;
    spotCancelIngestEndPoint: string;
    spotReprocessEndPoint: string;
    spotRelinkEndPoint: string;
    spotChangeDownconvertEndPoint: string;
    spotReslateEndPoint: string;
    spotProdSvcsEndPoint: string;
    spotCCEndPoint: string;
    spotApplyQcEndPoint: string;
    spotDeliveredAssetEndPoint: string;
    retrieveOffsiteMediaEndPoint: string;
    checkSpotReslateEndPoint: string;

    // Orders
    orderEndPoint: string;
    orderStatusEndPoint: string;
    orderCostEstimateEndPoint: string;
    orderRecallEndPoint: string;
    orderCheckTeamCompaniesEndPoint: string;
    validateSpotsEndPoint: string;
    orderDeliveryConfirmationEndPoint: string;
    orderReceiptConfirmationEndPoint: string;
    orderHoldReasonEndPoint: string;
    makelistEndPoint: string;
    specialOrderTypeEndPoint: string;
    uploadTrafficAssetEndpoint: string;
    orderItemEndPoint: string;
    importGroupEndPoint: string;
    exportOrderEndPoint: string;
    downloadTrafficAssetEndPoint: string;
    draftTrafficEndPoint: string;
    versionEndPoint: string;
    orderMetadataEndpoint: string;
    digitalValidationEndPoint: string;
    availableCadHostsEndPoint: string;
    cancelOrderEndPoint: string;
    refreshBatonEndPoint: string;
    refreshFlipEndPoint: string;
    refreshVantageEndPoint: string;
    enumEndPoint: string;
    xferClientEndPoint: string;
    handshakeEndPoint: string;
    liteBrandingEndPoint: string;
    liteUrlEndPoint: string;
    systemEventsEndPoint: string;
    assetStreamEndpoint: string;
    assetDownloadEndpoint: string;
    assetDownloadReportEndpoint: string;
    generateProxyEndpoint: string;
    assetQcReportEndpoint: string;
    guideEndPoint: string;
    guideDownloadEndPoint: string;
    preferenceEndPoint: string;
    invoiceEndPoint: string;
    invoiceDownloadEndPoint: string;
    termsAndConditionsEndPoint: string;
    deliveryConfirmationEndPoint: string;
    cancelTapJobOrderEndPoint: string;

    // Dashboard
    dashboardEndPoint: string;
    asperaByAccountIdEndPoint: string;

    // VAST
    retrieveVastXmlEndpoint: string;
    //integrations
    integrationsAccountEndpoint: string;

    constructor() {
        this.apiServerUrl = '';

        this.socketEndpoint = this.apiServerUrl + '/ripley';

        this.loginEndpoint = this.apiServerUrl + '/reachengine/api/security/users/login';
        this.logoutEndpoint = this.apiServerUrl + '/reachengine/api/security/users/logout';
        this.registerLiteEndpoint = this.apiServerUrl + '/reachengine/api/security/users/register';
        this.switchAccountEndPoint = this.apiServerUrl + '/api/switchAccount';
        this.sessionKeyEndPoint = this.apiServerUrl + '/api/sessionkey';

        this.passwordResetEndPoint = this.apiServerUrl + '/passwordReset';
        this.passwordChangeEndPoint = this.apiServerUrl + '/changePassword';

        this.viewEulaEndPoint = this.apiServerUrl + '/page/eula';
        this.acceptEulaEndPoint = this.apiServerUrl + '/acceptEula';
        this.agenciesEndPoint = this.apiServerUrl + '/agencies';
        this.advertisersEndPoint = this.apiServerUrl + '/advertisers';
        this.brandsEndPoint = this.apiServerUrl + '/brands';
        this.receiverBrandsEndPoint = this.apiServerUrl + '/api/receiver/brands';
        this.campaignsEndPoint = this.apiServerUrl + '/campaigns';
        this.receiverCampaignsEndPoint = this.apiServerUrl + '/api/receiver/campaigns';
        this.batonTestPlanEndPoint = this.apiServerUrl + '/batonTestPlans';
        this.destinationEndPoint = this.apiServerUrl + '/api/destinations';
        this.grantedDestinationsEndPoint = this.apiServerUrl + '/api/provider/destinations';
        this.orderGroupDestinationsEndPoint = this.apiServerUrl + '/api/provider/orderGroup/:orderGroupId/destinations';

        this.destinationTypeEndPoint = this.apiServerUrl + '/destinationTypes';
        this.destinationListEndPoint = this.apiServerUrl + '/destinationLists';
        this.destinationListImportEndPoint = this.apiServerUrl + '/destinationLists/newImport';
        this.destinationListWithSystemEndPoint = this.apiServerUrl + '/destinationListsWithSystem';

        this.durationListEndPoint = this.apiServerUrl + '/durationLists';

        this.welcomeMessageEndPoint = this.apiServerUrl + '/api/welcomeMessages';

        this.factoryEndPoint = this.apiServerUrl + '/factories';
        this.vantageFactoryEndPoint = this.apiServerUrl + '/vantage';
        this.marketEndPoint = this.apiServerUrl + '/markets';
        this.mediaFormatEndPoint = this.apiServerUrl + '/mediaFormats';
        this.securityRoleEndPoint = this.apiServerUrl + '/securityRoles';
        this.securityRolePermContextEndPoint = this.apiServerUrl + '/api/securityRolePermContexts';

        this.jobsEndPoint = this.apiServerUrl + '/jobs';
        this.clusterNodeEndPoint = this.apiServerUrl + '/clusterNodes';

        this.audioChannelEndPoint = this.apiServerUrl + '/audioChannels';

        this.acceptTemplateEndPoint = this.apiServerUrl + '/accepttemplates';
        this.spotMetadataTransFormEndPoint = this.apiServerUrl + '/spotMetadataTransforms';
        this.videoFormatProfileEndPoint = this.apiServerUrl + '/videoFormatProfiles';
        this.userEndPoint = this.apiServerUrl + '/users';
        this.jobClassificationEndPoint = this.apiServerUrl + '/jobclassifications';
        this.accountEndPoint = this.apiServerUrl + '/accounts';
        this.accountFriendOfEndPoint = this.apiServerUrl + '/friendOf/:id';
        this.accountFriendsEndPoint = this.apiServerUrl + '/friends/:id';
        this.providerEndPoint = this.apiServerUrl + '/providers';
        this.providerListEndPoint = this.apiServerUrl + '/providersList';
        this.receiverEndPoint = this.apiServerUrl + '/receivers';
        this.receiverListEndPoint = this.apiServerUrl + '/receiversList';
        this.tapeVendorEndPoint = this.apiServerUrl + '/tapevendors';
        this.qcVendorEndPoint = this.apiServerUrl + '/qcvendors';
        this.prodSvcsVendorEndPoint = this.apiServerUrl + '/prodsvcsvendors';

        // Deliveries
        this.deliveryEndPoint = this.apiServerUrl + '/api/receiver/deliveries';
        this.deliveryProviderEndPoint = this.apiServerUrl + '/api/provider/deliveries';
        this.deliveryAssetMapEndPoint = this.apiServerUrl + '/api/receiver/deliveries/:id/assetMap';
        this.deliveryTrafficAssetEndPoint = this.apiServerUrl + '/api/receiver/deliveries/:id/traffic';
        this.deliveryCorollaryInfoEndPoint = this.apiServerUrl + '/api/receiver/deliveries/:id/info';
        this.customizationEndPoint = this.apiServerUrl + '/api/receiver/deliveries/:id/customization';
        this.liteDeliveryEndPoint = this.apiServerUrl + '/api/lite/deliveries';
        this.deliveryOriginalEndPoint = this.apiServerUrl + '/api/receiver/deliveries/:id/originalEntry';
        this.deliveryProxyEndPoint = this.apiServerUrl + '/api/receiver/deliveries/:id/proxyEntry';
        this.referenceLinkEndPoint = this.apiServerUrl + '/api/provider/referencelink/:orderId/:spotId';
        this.metadataOverrideEndPoint = this.apiServerUrl + '/api/provider/metadataOverride/:orderId/:spotId/:orderGroupId';

        // Spots
        this.spotEndPoint = this.apiServerUrl + '/api/spots';
        this.spotStatusEndPoint = this.apiServerUrl + '/api/provider/spotStatusCounts';
        this.checkIsciEndPoint = this.apiServerUrl + '/api/provider/isci';
        this.tapIngestEndPoint = this.apiServerUrl + '/api/spots/:id/ingest/media';
        this.tapUploadEndPoint = this.apiServerUrl + '/api/spots/ingest/media';
        this.providerSpotEndPoint = this.apiServerUrl + '/api/provider/spots';
        this.spotSubmitEndPoint = this.apiServerUrl + '/api/spot/submission';
        this.spotUploadEndPoint = this.apiServerUrl + '/api/spot/upload';
        this.uploadCCAssetEndpoint = this.apiServerUrl + '/api/spot/cc/file';
        this.uploadCCSubmitEndpoint = this.apiServerUrl + '/api/spot/cc/submit';
        this.spotImportEndPoint = this.apiServerUrl + '/api/spots/importFile';
        this.spotAssetMapEndPoint = this.apiServerUrl + '/api/spots/:id/assets';
        this.spotAssetStatsEndPoint = this.apiServerUrl + '/api/spots/:id/assetStats';
        this.spotMetadataEndPoint = this.apiServerUrl + '/api/spots/metadata';
        this.spotRecallEndPoint = this.apiServerUrl + '/api/spot/:id/recall';
        this.spotVerifyEndPoint = this.apiServerUrl + '/api/spot/verify';
        this.spotAssignEndPoint = this.apiServerUrl + '/api/spot/assign';
        this.spotSetFriendsEndPoint = this.apiServerUrl + '/api/spot/set/friends';
        this.spotGetAdIDEndPoint = this.apiServerUrl + '/api/spot/getadid';
        this.spotSaveAdIDEndPoint = this.apiServerUrl + '/api/spot/saveadid';
        this.spotCancelIngestEndPoint = this.apiServerUrl + '/api/spots/:id/cancelIngest';
        this.spotReprocessEndPoint = this.apiServerUrl + '/api/spot/reprocess';
        this.spotRelinkEndPoint = this.apiServerUrl + '/api/spot/relinkSdHdSpot';
        this.spotChangeDownconvertEndPoint = this.apiServerUrl + '/api/spot/changeDownconvertPreference';
        this.spotReslateEndPoint = this.apiServerUrl + '/api/spot/reslate';
        this.spotProdSvcsEndPoint = this.apiServerUrl + '/api/spot/:id/requestProdSvcs';
        this.spotCCEndPoint = this.apiServerUrl + '/api/spot/:id/requestCC';
        this.spotApplyQcEndPoint = this.apiServerUrl + '/api/spot/:id/qcValidation';
        this.spotDeliveredAssetEndPoint = this.apiServerUrl + '/api/spots/:id/deliveredAsset';
        this.retrieveOffsiteMediaEndPoint = this.apiServerUrl + '/api/spots/:id/offsiteMedia';
        this.checkSpotReslateEndPoint = this.apiServerUrl + '/api/spots/:id/reslateNeeded';

        // Orders
        this.orderEndPoint = this.apiServerUrl + '/api/provider/orders';
        this.orderStatusEndPoint = this.apiServerUrl + '/api/provider/orderStatusCounts';
        this.orderCostEstimateEndPoint = this.apiServerUrl + '/api/asset/reports/download/ce';
        this.orderRecallEndPoint = this.apiServerUrl + '/api/order/:id/recall';
        this.orderCheckTeamCompaniesEndPoint = this.apiServerUrl + '/api/provider/orders/:id/rightsWindow';
        this.validateSpotsEndPoint = this.apiServerUrl + '/api/provider/orders/:id/spotValidation';
        this.orderDeliveryConfirmationEndPoint = this.apiServerUrl + '/api/asset/reports/download/dc';
        this.orderReceiptConfirmationEndPoint = this.apiServerUrl + '/api/asset/reports/download/rcr';
        this.orderHoldReasonEndPoint = this.apiServerUrl + '/api/provider/orderHoldReasonType';
        this.makelistEndPoint = this.apiServerUrl + '/api/provider/makelist';
        this.specialOrderTypeEndPoint = this.apiServerUrl + '/api/provider/specialOrderType';
        this.uploadTrafficAssetEndpoint = this.apiServerUrl + '/api/provider/traffic/asset';
        this.orderItemEndPoint = this.apiServerUrl + '/api/provider/orders/:id/orderItems';
        this.importGroupEndPoint = this.apiServerUrl + '/api/provider/orders/importOrder';
        this.exportOrderEndPoint = this.apiServerUrl + '/api/provider/orders/:id/export';
        this.downloadTrafficAssetEndPoint = this.apiServerUrl + '/api/provider/orders/:id/deliveredTraffic';
        this.draftTrafficEndPoint = this.apiServerUrl + '/api/asset/reports/download/traffic';
        this.versionEndPoint = this.apiServerUrl + '/version';
        this.orderMetadataEndpoint = this.apiServerUrl + '/api/orders/metadata';
        this.digitalValidationEndPoint = this.apiServerUrl + '/api/orders/validateDigital';
        this.availableCadHostsEndPoint = this.apiServerUrl + '/api/cad/direct';
        this.cancelOrderEndPoint = this.apiServerUrl + '/api/orders/actions';
        this.cancelTapJobOrderEndPoint = this.apiServerUrl + '/api/tapOrders/actions/cancel';
        this.refreshBatonEndPoint = this.apiServerUrl + '/refreshBaton';
        this.refreshFlipEndPoint = this.apiServerUrl + '/refreshFlip';
        this.refreshVantageEndPoint = this.apiServerUrl + '/refreshVantage';
        this.enumEndPoint = this.apiServerUrl + '/enums';
        this.xferClientEndPoint = this.apiServerUrl + '/api/xferClients';

        this.handshakeEndPoint = this.apiServerUrl + '/handshake';

        this.liteBrandingEndPoint = this.apiServerUrl + '/api/branding/lite';
        this.liteUrlEndPoint = this.apiServerUrl + '/api/branding/urls';

        this.systemEventsEndPoint = this.apiServerUrl + '/api/systemEvents';

        this.assetStreamEndpoint = this.apiServerUrl + '/api/asset';

        this.assetDownloadEndpoint = this.apiServerUrl + '/api/asset/downloads';
        this.assetDownloadReportEndpoint = this.apiServerUrl + '/api/asset/reports/download';
        this.generateProxyEndpoint = this.apiServerUrl + '/api/spots/:id/proxy';
        this.assetQcReportEndpoint = this.apiServerUrl + '/api/asset/reports/qc';

        this.guideEndPoint = this.apiServerUrl + '/api/userGuideDocuments';
        this.guideDownloadEndPoint = this.apiServerUrl + '/api/userGuideDocuments/stream/:id';
        this.preferenceEndPoint = this.apiServerUrl + '/api/user/:id/preferences';

        this.invoiceEndPoint = this.apiServerUrl + '/api/invoices';
        this.invoiceDownloadEndPoint = this.apiServerUrl + '/api/invoices/invoiceAsset';

        this.termsAndConditionsEndPoint = this.apiServerUrl + '/api/terms/:id';

        this.deliveryConfirmationEndPoint = this.apiServerUrl + '/api/deliveryconfirmation/:id';

        //Dashboard
        this.dashboardEndPoint = this.apiServerUrl + '/api/dashboards';
        this.asperaByAccountIdEndPoint = this.apiServerUrl + '/api/asperaDetailsByAccountId/:id';
        //Vast
        this.retrieveVastXmlEndpoint = this.apiServerUrl + '/api/vast/asset/xml';
        //integrations Account
        this.integrationsAccountEndpoint = this.apiServerUrl + '/api/integrationsAccount';
    }

    getApiUrl():string {
        return this.apiServerUrl;
    }
}

angular.module('comcast.common.communication').factory('EndPointService', downgradeInjectable(EndPointService));
