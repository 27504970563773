angular.module('comcast.common.communication').service('EnumService', ['EnumResourceFactory', '$log', '$q', function (EnumResourceFactory, $log, $q) {
  var enums;
  var loading = false; // objects like { key: string value, deferred: js $q deferred obj }

  var pendingRequests = [];

  function enumQuerySuccessCallback(enumData) {
    loading = false;
    applyNewEnums(enumData);
  }

  function enumQueryErrorCallback(error) {
    loading = false;
    $log.warn('failed to query for enums'); // reject all pending requests b/c we failed

    angular.forEach(pendingRequests, function (request) {
      request.deferred.reject(null);
    });
  }

  function doUpdateEnums() {
    loading = true;
    EnumResourceFactory.query(enumQuerySuccessCallback, enumQueryErrorCallback);
  }

  function applyNewEnums(data) {
    enums = data;
    angular.forEach(pendingRequests, function (request) {
      if (enums[request.family] && enums[request.family][request.key]) {
        request.deferred.resolve(enums[request.family][request.key].picklist);
      } else {
        request.deferred.reject(null);
      }
    });
  } // Get an enum. Family refers to what object it is related to on the backend, and therefore returned under in the
  // call to the dataObjects/meta endpoint.


  function doGetEnum(family, keyArg) {
    var def = $q.defer();

    if (enums === undefined) {
      doUpdateEnums();
    } // if we are querying for enums still, add to pending requests


    if (loading) {
      pendingRequests.push({
        family: family,
        key: keyArg,
        deferred: def
      });
    } else if (enums && enums[family] && enums[family][keyArg]) {
      // else resolve immediately with what we have for this enum (might be nothing)
      def.resolve(angular.copy(enums[family][keyArg].picklist));
    } else {
      def.resolve([]);
    }

    return def.promise;
  } // look up enum label for a given enum array & value.


  function doEnumLabelLookup(enumArray, value) {
    var retVal = '';

    if (enumArray && enumArray.length > 0) {
      for (var i = 0, len = enumArray.length; i < len; i++) {
        if (enumArray[i].value === value) {
          retVal = enumArray[i].label;
          break;
        }
      }
    }

    return retVal;
  }

  return {
    updateEnums: doUpdateEnums,
    getEnum: doGetEnum,
    getEnumLabel: doEnumLabelLookup,
    DISTRIBUTION_TYPE: 'distributionType',
    DESTINATION_DISTRIBUTION_TYPE: 'destinationDistributionType',
    STATES: 'states'
  };
}]);