import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

declare var angular: angular.IAngularStatic;

@Injectable({
    providedIn: 'root',
})
export class AppScopeService {
    public savedLocation:string|undefined;

    constructor() {
        this.savedLocation = undefined;
    }

    setSavedLocation(newVal:string|undefined) {
        this.savedLocation = newVal;
    }

    getSavedLocation():string|undefined {
        return this.savedLocation;
    }
}

angular.module('comcast.common.communication').factory('AppScopeService', downgradeInjectable(AppScopeService));
