// Declare the global values used in this Component
declare var angular: angular.IAngularStatic;

import { Component, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    [index: number]: {
        progress: number,
        filename?: string,
    }
}

@Component({
    selector: 'cts-bulk-upload-progress-dialog',
    template: require('./cts-bulk-upload-progress-dialog.component.html'),
})
export class CtsBulkUploadProgressDialog {

    constructor(
        public dialogRef: MatDialogRef<CtsBulkUploadProgressDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {
        // Dump the list of uploads into the console to begin dev on this component
        console.log(this.data);
    }

}

angular.module("comcast.directives").directive(
    'ctsBulkUploadProgressDialog',
    downgradeComponent(
        // The inputs and outputs here must match the relevant names of the properties on the
        // "downgraded" component
        {component: CtsBulkUploadProgressDialog, inputs: [], outputs: []}));
