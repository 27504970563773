// Declare the global values used in this Component
declare var angular: angular.IAngularStatic;

import { Component } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'cts-bulk-upload-instructions-dialog',
    template: require('./cts-bulk-upload-instructions-dialog.component.html'),
})
export class CtsBulkUploadInstructionsDialog {

    constructor(
        public dialogRef: MatDialogRef<CtsBulkUploadInstructionsDialog>,
    ) {}

}

angular.module("comcast.directives").directive(
    'ctsBulkUploadInstructionsDialog',
    downgradeComponent(
        // The inputs and outputs here must match the relevant names of the properties on the
        // "downgraded" component
        {component: CtsBulkUploadInstructionsDialog, inputs: [], outputs: []}));
